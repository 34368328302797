<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="2">账套</el-col>
          <el-col :span="2"><el-input v-model="params.remark" size="mini"></el-input></el-col>
          <el-col :span="2"><el-button @click="loaddata" size='mini'>账套查询</el-button></el-col>
          <el-col :span="2">类别</el-col>
          <el-col :span="2"><el-input v-model="params.details.type"  size="mini"></el-input></el-col>
          <el-col :span="2">编码</el-col>
          <el-col :span="2"><el-input v-model="params.details.code" size="mini"></el-input></el-col>
          <el-col :span="2">名称</el-col>
          <el-col :span="2"><el-input v-model="params.details.name" size="mini"></el-input></el-col>
          <el-col :span="2"><el-button @click="selectDefine" size="mini">查询</el-button></el-col>
          <el-col :span="2"><el-button @click="syc" size="mini">同步</el-button></el-col>
          <!-- <el-col :span="2"><el-button @click="sycall">全部同步</el-button></el-col> -->
        </el-row>
      </el-header>
      <el-container>
        <div style="width: 520px">
          <el-table :data="accounts" @selection-change="handleSelectionChange" height="600">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="acc2" label="数据库"></el-table-column>
            <el-table-column prop="accountName" label="套账"></el-table-column>
            <el-table-column prop="sycval" label="源值"></el-table-column>
          </el-table>
        </div>
        <div style="width: 820px">
          <el-table :data="defines" @selection-change="handleSelectionChange2"  height="600">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="code" label="编码"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="disabled" label="是否停用"></el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="params.page.total"
            :page-size="params.page.size"
            >
          </el-pagination>
        </div>
        <el-main></el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import ax from "@/js/ax.js";
export default {
  name: "account",
  extends: ax.control(),
  data() {
    return {
      accounts: [],
      defines: [],
      multipleSelection: [],
      multipleSelection1: [],
      height: document.documentElement.clientHeight,
      currentPage4:4,
      params:{
        details:{
          code:"",
          name:"",
          type:"define_001",
          valcode:"",
        },
        page:{
          size:600,
          current:1,
          total:0
        }
      }
    };
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.params.page.current = val;
        this.selectDefine();
      },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection1 = val;
    },
    sycall(){

    },
    syc() {
      let _this = this;
      ax.base
        .post("/syc/sycdefine", {
          details: this.multipleSelection,
          classes: this.multipleSelection1,
        })
        .then((data) => {
          _this.$message({ type: "success", message: data.data });
        })
        .catch((data) => {
          _this.$message({ type: "error", message: data.data });
        });
    },
    selectDefine(){
      let _this = this
      this.params.details.valcode = this.accounts[0]['sycval']
       ax.base.post("/syc/selectDefine", _this.params)
        .then((data) => {
          console.log(data.data)
          console.log(data.data.total)
          _this.defines = data.data.records;
          _this.params.page.total = data.data.total;
        })
        .catch((data) => {
        });
    },
    loaddata() {
      let _this = this;
      ax.base
        .post("/syc/getDetail", { type: _this.params.details.type,remark:this.params.remark })
        .then((data) => {
          _this.accounts = data.data;
        })
        .catch((data) => {
        });
        //this.selectDefine();
    },
  },
};
</script>
<style scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  /* text-align: center; */
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center; */
  line-height: 600px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  /* line-height: 260px; */
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>